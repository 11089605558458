// accueil.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import imageRight from '../../assets/images/carRapideTrans.png';
import imagePlayStore from '../../assets/images/google-play-badge.png';
import imageAppStore from '../../assets/images/app-store-badge.png';
import iconCheck from '../../assets/images/check.png';
import bonHomme from '../../assets/images/bon-homme.png';
import './accueil.css';
import logo from '../../assets/images/logo-code-senegal.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Accueil() {

  function scrollCheck() {
    window.document.getElementById('rapide').style.top = window.pageYOffset / 1.5 + 'px';
    window.document.getElementById('rapide').style.right = window.pageYOffset / 6 + 'px';
    if (window.pageYOffset > 80) {
      window.document.getElementById('navbar').classList.add('nav-bar-light');
    } else {
      window.document.getElementById('navbar').classList.remove('nav-bar-light');
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollCheck);
    return () => {
      window.removeEventListener("scroll", scrollCheck)
    }
  }, [])


  return (
    <div className="accueil-container" >

      <script src="https://cdn.jsdelivr.net/npm/less" ></script>

      {/* ----------------- NAVBAR --------------------- */}
      <section className="nav-bar" id='navbar'>
        <img src={logo} alt="Logo de l'entreprise" />
        <nav>
          <ul>
            <li><a href="#accueil">ACCUEIL</a></li>
            <li><a href="#fonctionnalite">FONCTIONNALITES</a></li>
            <li><a href="#apropos">POURQUOI CHOISIR L'APP</a></li>
          </ul>
        </nav>
      </section>

      {/* ----------------- BANNIERE --------------------- */}
      <section id="accueil" className="row banner" >

        <div className="col-12 col-md-6 left-content">
          <h1 className='title'>Code de la route Sénégal</h1>
          <h3 className='slogan'>Votre clé du succès au code de la route au Sénégal !</h3>
          <div className="row downloadsHead">
            <div className='col-6'>
              <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                <img height={50} src={imagePlayStore} alt="Image playstore" />
              </a>
            </div>
            <div className='col-6'>
              <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                <img height={50} src={imageAppStore} alt="Image appstore" />
              </a>
            </div>
          </div>
        </div>

        <div className="col-6 d-none d-sm-none d-md-block right-content" o>
          <img src={imageRight} alt="Image à droite" id='rapide' />
        </div>

      </section>


      {/*-------------Fonctionnalités--------------------- */}
      <section className="section-fonctionnalite">
        <h2>FOCTIONNALITÉS</h2>
        <div className="fonctionnalites row">

          <div className="fonctionnalite col-12 col-md-6">
            <div>
              <span role="img" aria-label="Leçons Interactives">📘</span>
              <h3>Leçons Interactives</h3>
              <p>
                <div>Apprenez les règles de conduite de manière ludique grâce à nos leçons interactives et faciles à comprendre.</div>
              </p>
            </div>
          </div>

          <div className="fonctionnalite col-12 col-md-6">
            <div>
              <span role="img" aria-label="Tests Pratiques">🚥</span>
              <h3>Tests Pratiques </h3>
              <p>
                <div>Mettez vos connaissances à l'épreuve avec une variété de tests pratiques qui simulent l'examen réel.</div>
              </p>
            </div>
          </div>

          <div className="fonctionnalite col-12 col-md-6">
            <div>
              <span role="img" aria-label="Statistiques de Performance">🚀</span>
              <h3>Statistiques de Performance</h3>
              <p>
                <div>Suivez vos progrès et identifiez vos points forts et vos points faibles pour une préparation ciblée.</div>
              </p>
            </div>
          </div>

          <div className="fonctionnalite col-12 col-md-6">
            <div>
              <span role="img" aria-label="Communauté d'Apprenants">👥</span>
              <h3>Communauté d'Apprenants</h3>
              <p>
                <div>Rejoignez une communauté dynamique d'autres candidats, partagez des conseils et motivez-vous mutuellement.</div>
              </p>
            </div>
          </div>

        </div>
      </section>

      {/*-------------CONTENUE--------------------- */}
      <section className="section-contenue">
        <h2 className='title'>CONTENUE</h2>
        <div className="row contenue">
          <div className="col-12 col-md-6">
            <img src={bonHomme} alt="Image à droite" />
          </div>
          <div className="col-12 col-md-6">
            <h3 className='title'>L'appliction contient : </h3>
            <ul>
              <li>
                <img width={40} src={iconCheck} alt="Image à droite" />
                Cours de code pour apprendre les règles du code de la route
              </li>
              <li>
                <img width={40} src={iconCheck} alt="Image à droite" />
                Series de tests pour vous entrainer à volonté.
              </li>
              <li>
                <img width={40} src={iconCheck} alt="Image à droite" />
                Examens Blanc dans les même conditions que l'examen officiel
              </li>
              <li>
                <img width={40} src={iconCheck} alt="Image à droite" />
                Historique des tests effectués permettant de revoir ses erreurs
              </li>
              <li>
                <img width={40} src={iconCheck} alt="Image à droite" />
                Dossier personnel avec création de comptes utilisateurs
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section-captures">
        <h2 className='title'>CAPTURES</h2>
        {/* <Slider
          className='imagesCarrousel with-shadow'
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3000}
          cssEase="ease-in-out"
          pauseOnHover={false}
          pauseOnFocus={false}
          fade={true}
        >
          <div className="carrouselImages">
            <img className="centered-image" height={500} src={codeRoute} alt="Image 3" />
          </div>
          <div>
            <img className="centered-image" height={500} src={image1} alt="Image 1" />
          </div>
          <div>
            <img className="centered-image" height={500} src={image4} alt="Image 1" />
          </div>
        </Slider> */}
      </section>

      {/*-------------CHIFFRE CLES--------------------- */}
      <section className="section-chiffre-cle">
        {/* <h2 className='title'>CHIFFRES CLÉS</h2> */}
        <div className='row chiffres'>
          <div className='col col-12 col-md-4'>
            <div className='number'> +5000</div>
            <div className='title'>Téléchargements</div>
            <div className='desc'>Téléchargements sur toutes les platformes</div>
          </div>
          <div className='col col-12 col-md-4'>
            <div className='number'>+5000</div>
            <div className='title'>PlayStore</div>
            <div className='desc'>Téléchargements sur PlayeStore</div>
          </div>
          <div className='col col-12 col-md-4'>
            <div className='number'>-</div>
            <div className='title'>AppStore</div>
            <div className='desc'>Téléchargements sur AppStore</div>
          </div>
        </div>
      </section>

      {/* POURQUOI LE CHOISIR */}
      <section id="apropos" className="choisir">
        <h2>POURQUOI CHOISIR L'APPLICATION</h2>
        <ul>
          <li className='margin-right'>
            <strong>✅ Chance de Réussite Élevé:</strong><br />
            Grâce à notre approche pédagogique efficace, vous avez une chance de réussir votre examen!
            {/* <progress value="95" max="100"></progress>99% */}
          </li>
          <li className='margin-left'>
            <strong>💼 Actualisée selon les Normes Sénégalaises:</strong><br />
            Notre contenu est constamment mis à jour pour refléter les dernières règles et réglementations
            {/* routières du Sénégal. */}
            {/* <progress value="100" max="100"></progress>100% */}
          </li>
          <li className='margin-right'>
            <strong>📱 Accessibilité Totale:</strong><br />
            Préparez-vous où que vous soyez, à tout moment, avec notre application disponible sur AppStore et PlayStore .
            {/* <progress value="98" max="100"></progress>98% */}
          </li>
        </ul>
      </section>

      {/* NOUS CONTACTEZ */}
      <section id="nous-contactez" className="nous-contactez">
        <h2>NOUS CONTACTEZ</h2>
        <div className='row'>
          <div className="col-12 col-md-6">
            <div className='row p-4'>

              <div className='contact-item col-6' onClick={() => window.open("https://maps.app.goo.gl/xWKYKj98xxBotTH97")} >
                <div className='contact-item-content'>
                  <h3>Adresse</h3>
                  <p>Sénégal, Dakar, Parcelles asainies unité 12 n°527</p>
                </div>
              </div>

              <div className='contact-item col-6' onClick={() => window.open("mailto:amogotech.com")} >
                <div className='contact-item-content'>
                  <h3>Mail</h3>
                  <p>amogotech@gmail.com</p>
                </div>
              </div>

              <div className='contact-item col-6' onClick={() => window.open("tel:+221772621717")} >
                <div className='contact-item-content'>
                  <h3>Téléphone</h3>
                  <p>+221772621717</p>
                </div>
              </div>

              <div className='contact-item col-6' onClick={() => window.open("https://wa.me/+221772621717")} >
                <div className='contact-item-content'>
                  <h3>WhatsApp</h3>
                  <p>+221772621717</p>
                </div>
              </div>

            </div>
          </div> <div className="col-12 col-md-6">
            <form className='formulaire'>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">Nom complet</label>
                <input type="text" className="form-control" aria-describedby="emailHelp" />
              </div>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">Email</label>
                <input type="email" className="form-control" aria-describedby="emailHelp" />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">Message</label>
                <textarea type="password" className="form-control" id="exampleInputPassword1" />
              </div>
              <button type="submit" className="btn btn-success">Submit</button>
            </form>
          </div>
        </div>
      </section>

      {/*-------------CAPTURE--------------------- */}
      <section className="section-avant-pied">
      </section>

      {/* Section Logo Wave */}
      <section id="logo-wave" className="section-4">

        <div>
          <img height={100} src={logo} alt="Logo de l'entreprise" />
        </div>

        {/* Liens */}
        {/* <div>
          <h3>L’entreprise</h3>
          <ul>
            <li>À Propos</li>
            <li>Carrières</li>
            <li>Blog</li>
          </ul>
        </div> */}

        <div>
          <h3>Informations Légales</h3>
          <ul>
            {/* <li>Conditions Générales</li> */}
            <li><a href="/suppression">Suppression</a></li>
            <li><a href="/confidentialite">Avis de Confidentialité</a></li>
            <li><a href="/support">support</a></li>
          </ul>
        </div>

        {/* Téléchargements */}
        <div className="downloads">
          <h3>Télécharger</h3>
          <div className='images'>
            <div>
              <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                <img height={50} src={imagePlayStore} alt="Image à droite" id='rapide' />
              </a>
            </div>
            <div>
              <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                <img height={50} src={imageAppStore} alt="Image à droite" id='rapide' />
              </a>
            </div>
          </div>
        </div>

      </section >

      <section className="section-5">
        <span className='annee'>2024</span> © by AMOGOTech.
      </section>

    </div >
  );
}

export default Accueil;
