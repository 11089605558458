import './App.css';
import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confidentialite from './screens/confidentialite';
import Suppression from './screens/suppression';
import Accueil from './screens/accueil/accueil';
import AppToStore from './screens/appToStore/appToStore';
import Support from './screens/support/support';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Accueil />} />
        <Route path='confidentialite' element={<Confidentialite />} />
        <Route path='support' element={<Support />} />
        <Route path='suppression' element={<Suppression />} />
        <Route path='app' element={<AppToStore />} />
      </Routes>
    </BrowserRouter>
  );
  
}

export default App;
