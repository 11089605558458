import React from 'react';
import { Link } from 'react-router-dom';
import './confidentialite.css';
import logo from '../assets/images/5.jpeg';

export default function Confidentialite() {
  return (
    <div>
      
     <section className="logoMenu-section">
        <img id='imageConf' src={logo} alt="Logo de l'entreprise"/>
        <nav>
          <ul id='menuConf'>
          <li><Link to="/">Accueil</Link></li>
          </ul>
        </nav>
      </section>

     <section className="textConfidentialite">
      <h1 id='titleConf'>Avis de Confidentialité pour l'Application Auto-École</h1>
      <p><strong>Dernière mise à jour :</strong> [Date]</p>

      <p>Bienvenue sur l'application Auto Ecole. La confidentialité de vos données est une priorité pour nous. Cet avis de confidentialité explique quelles informations sont collectées, comment elles sont utilisées, et les choix que vous avez concernant ces informations.</p>

      <h2>1. Informations Collectées</h2>

      <p><h3>Nous pouvons collecter les types d'informations suivants lorsque vous utilisez notre application :</h3></p>
      <ul>
        <li>Informations personnelles : Nom, prénom, adresse e-mail, numéro de téléphone, etc.</li>
        <li>Données de navigation : Pages visitées, temps passé sur l'application, etc.</li>
        <li>Données de l'appareil : Type d'appareil, système d'exploitation, identifiants uniques, etc.</li>
      </ul>

      <h2>2. Utilisation des Informations</h2>

      <p>Nous utilisons les informations collectées pour les finalités suivantes :</p>
      <ul>
        <li>Fournir et personnaliser nos services liés à la préparation à l'examen du code de la route.</li>
        <li>Améliorer l'expérience utilisateur.</li>
        <li>Répondre à vos demandes et offrir un support client.</li>
        <li>Envoyer des mises à jour, des alertes et des informations pertinentes liées à l'application.</li>
      </ul>

      <h2>3. Partage des Informations</h2>

      <p>Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers sans votre consentement, sauf dans les cas suivants :</p>
      <ul>
        <li>Lorsque requis par la loi ou pour répondre à une demande légale.</li>
        <li>Pour protéger nos droits, notre propriété ou notre sécurité, ainsi que ceux de nos utilisateurs.</li>
      </ul>

      <h2>4. Sécurité</h2>

      <p>Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction.</p>

      <h2>5. Vos Choix</h2>

      <p>Vous pouvez choisir de ne pas fournir certaines informations, mais cela peut affecter votre utilisation de certaines fonctionnalités de l'application.</p>

      <h2>6. Modifications de l'Avis de Confidentialité</h2>

      <p>Nous nous réservons le droit de mettre à jour cet avis de confidentialité à tout moment. Les modifications seront effectives dès leur publication sur l'application.</p>

      <h2>7. Nous Contacter</h2>

      <p>Si vous avez des questions concernant cet avis de confidentialité, veuillez nous contacter à [adresse e-mail ou coordonnées].</p>

      <p>En utilisant notre application, vous consentez à la collecte et à l'utilisation de vos informations conformément à cet avis de confidentialité.</p>
      </section>
     
      <section className="bas-de-page">
        <p><strong id='2024'>2024</strong>  <h8>© by AMOGO Tech .</h8></p>
      </section>

    </div>
  
  );
}
