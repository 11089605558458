// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoMenu-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
}

#imageConf{
  width: 80px;
  height: 80px;
}

#menuConf{
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 300px;
  min-width: 100%;
  transition: background-color 1s;
}
.textConfidentialite{
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 80px;
}  
  .container {
    margin: 80px 20px;
  }

  #titleConf{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    background-color: #555;
    color: white;
  }
  
  .bas-de-page {
    background-color: #0b2a47;
    color: white;
    text-align: center;
    margin-top: 50px;
  
}`, "",{"version":3,"sources":["webpack://./src/screens/confidentialite.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,+BAA+B;AACjC;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;;AAEpB","sourcesContent":[".logoMenu-section{\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  padding-left: 20px;\n}\n\n#imageConf{\n  width: 80px;\n  height: 80px;\n}\n\n#menuConf{\n  position: relative;\n  z-index: 9999;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  align-items: center;\n  height: 80px;\n  width: 300px;\n  min-width: 100%;\n  transition: background-color 1s;\n}\n.textConfidentialite{\n    margin-left: 100px;\n    margin-right: 100px;\n    margin-top: 80px;\n}  \n  .container {\n    margin: 80px 20px;\n  }\n\n  #titleConf{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 60px;\n    background-color: #555;\n    color: white;\n  }\n  \n  .bas-de-page {\n    background-color: #0b2a47;\n    color: white;\n    text-align: center;\n    margin-top: 50px;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
