// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .texteSuppression{
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 10px;
    margin-bottom: 50px;

  }

  .texteSuppression h4{
    margin-bottom: 30px;
    margin-top: 40px;
  }
  
  .logoMenu-section{
    margin-bottom: 80px;
  }
  
  #titreSuppression {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #555;
  }
  

  
  `, "",{"version":3,"sources":["webpack://./src/screens/suppression.css"],"names":[],"mappings":"EAAE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;;EAErB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;EACxB","sourcesContent":["  .texteSuppression{\n    margin-left: 100px;\n    margin-right: 100px;\n    margin-top: 10px;\n    margin-bottom: 50px;\n\n  }\n\n  .texteSuppression h4{\n    margin-bottom: 30px;\n    margin-top: 40px;\n  }\n  \n  .logoMenu-section{\n    margin-bottom: 80px;\n  }\n  \n  #titreSuppression {\n    display: flex;\n    justify-content: center;\n    color: white;\n    background-color: #555;\n  }\n  \n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
