import React, { useState } from 'react'
import './support.css';
import '../accueil/accueil.css';
import logo from '../../assets/images/logo-code-senegal.png';
import imagePlayStore from '../../assets/images/google-play-badge.png';
import imageAppStore from '../../assets/images/app-store-badge.png';
import { Link } from 'react-router-dom';

export default function Support() {

    const [showNumeber, setshowNumeber] = useState(1)

    return (
        <div>
            <script src="https://cdn.jsdelivr.net/npm/less" ></script>

            {/* ----------------- NAVBAR --------------------- */}
            <section className="nav-bar" id='navbar'>
                <img src={logo} alt="Logo de l'entreprise" />
                <nav>
                    <ul>
                        <li><a href="/#accueil">ACCUEIL</a></li>
                        <li><a href="/#fonctionnalite">FONCTIONNALITES</a></li>
                        <li><a href="/#apropos">POURQUOI CHOISIR L'APP</a></li>
                    </ul>
                </nav>
            </section>

            {/* ----------------- BANNIERE --------------------- */}
            <section id="accueil" className="row banner" >

                <div className="col-12 col-md-6 left-content">
                    <h1 className='title'>Support</h1>
                    <h3 className='slogan'>Votre clé du succès au code de la route au Sénégal !</h3>
                </div>

            </section>


            {/* FAQ */}
            <section id="faq" className="faq">
                <h2>FAQ</h2>
                <div className='collapses'>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 1 ? setshowNumeber(1) : setshowNumeber(0)}>
                            1. C'est quoi code de la route sénégal
                        </div>
                        <div className='body-colp' hidden={showNumeber != 1}>
                            L'application de code de la route Sénégal est une plateforme éducative conçue pour vous aider à
                            préparer votre examen de code de la route au Sénégal. Elle propose des cours, des tests pratiques
                            et des statistiques de progression.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 2 ? setshowNumeber(2) : setshowNumeber(0)}>
                            2. Comment puis-je télécharger l'application ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 2}>
                            L'application est disponible sur App Store et Google Play. Vous pouvez la télécharger
                            gratuitement en recherchant "Code de la Route Sénégal" et suivre les instructions 
                            pour l'installation.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 3 ? setshowNumeber(3) : setshowNumeber(0)}>
                            3. Comment créer un compte ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 3}>
                            Pour créer un compte, Vous devrez fournir votre numéro de téléphone et saisir le code OTP envoyé par SMS pour 
                            vérifier votre identité.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 4 ? setshowNumeber(4) : setshowNumeber(0)}>
                            4. Que faire si je ne reçois pas le code OTP ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 4}>
                            Si vous n'avez pas reçu le code OTP, vérifiez votre connexion réseau et assurez-vous 
                            que le numéro de téléphone est correctement saisi. Si le problème persiste, contactez notre 
                            support client à amogotech@gmail.com.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 5 ? setshowNumeber(5) : setshowNumeber(0)}>
                            5. Puis-je utiliser mon compte sur plusieurs appareils ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 5}>
                            Oui, vous pouvez utiliser votre compte sur plusieurs appareils. Connectez-vous simplement 
                            avec vos identifiants sur chaque appareil pour accéder à votre compte.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 6 ? setshowNumeber(6) : setshowNumeber(0)}>
                            6. Comment passer les tests pratiques ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 6}>
                            Pour passer les tests pratiques, choisissez le type de test
                             que vous souhaitez passer. Répondez aux questions et obtenez votre score à la fin du test.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 7 ? setshowNumeber(7) : setshowNumeber(0)}>
                            7. L'application ne se charge pas correctement. Que dois-je faire ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 7}>
                            Essayez de fermer et de rouvrir l'application. Assurez-vous également d'avoir une connexion
                             Internet stable. Si le problème persiste, essayez de redémarrer votre appareil.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 10 ? setshowNumeber(10) : setshowNumeber(0)}>
                            8. Puis-je accéder aux cours hors ligne ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 10}>
                            Actuellement, les cours nécessitent une connexion Internet pour être accessibles. 
                            Nous envisageons d'ajouter une fonctionnalité hors ligne dans une future mise à jour.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 8 ? setshowNumeber(8) : setshowNumeber(0)}>
                            9. Comment puis-je contacter le support client ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 8}>
                            Vous pouvez nous contacter par e-mail à amogotech@gmail.com pour toute question ou préoccupation.
                             Notre équipe se fera un plaisir de vous aider.
                        </div>
                    </div>

                    <div className='my-collapse'>
                        <div className='head-colp' onClick={() => showNumeber != 9 ? setshowNumeber(9) : setshowNumeber(0)}>
                            10. Puis-je proposer des suggestions pour améliorer l'application ?
                        </div>
                        <div className='body-colp' hidden={showNumeber != 9}>
                            Oui, nous apprécions vos commentaires ! Veuillez nous envoyer vos suggestions par e-mail 
                            ou via nos réseaux sociaux. Vos retours nous aident à améliorer continuellement notre application.
                        </div>
                    </div>




                </div>
            </section>

            {/* NOUS CONTACTEZ */}
            <section id="nous-contactez" className="nous-contactez">
                <h2>NOUS CONTACTEZ</h2>
                <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className='row p-4'>

                            <div className='contact-item col-6' onClick={() => window.open("https://maps.app.goo.gl/xWKYKj98xxBotTH97")} >
                                <div className='contact-item-content'>
                                    <h3>Adresse</h3>
                                    <p>Sénégal, Dakar, Parcelles asainies unité 12 n°527</p>
                                </div>
                            </div>

                            <div className='contact-item col-6' onClick={() => window.open("mailto:amogotech.com")} >
                                <div className='contact-item-content'>
                                    <h3>Mail</h3>
                                    <p>amogotech@gmail.com</p>
                                </div>
                            </div>

                            <div className='contact-item col-6' onClick={() => window.open("tel:+221772621717")} >
                                <div className='contact-item-content'>
                                    <h3>Téléphone</h3>
                                    <p>+221772621717</p>
                                </div>
                            </div>

                            <div className='contact-item col-6' onClick={() => window.open("https://wa.me/+221772621717")} >
                                <div className='contact-item-content'>
                                    <h3>WhatsApp</h3>
                                    <p>+221772621717</p>
                                </div>
                            </div>

                        </div>
                    </div> <div className="col-12 col-md-6">
                        <form className='formulaire'>
                            <div className="mb-3">
                                <label className="form-label">Nom complet</label>
                                <input type="text" className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Message</label>
                                <textarea type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                            <button type="submit" className="btn btn-success">Submit</button>
                        </form>
                    </div>
                </div>
            </section>

            {/* ------------------ PIED DE PAGE ------------------------------ */}
            <section id="logo-wave" className="section-4 pied">

                <div>
                    <img height={100} src={logo} alt="Logo de l'entreprise" />
                </div>

                <div>
                    <h3>Informations Légales</h3>
                    <ul>
                        <li><a href="/suppression">Suppression</a></li>
                        <li><a href="/confidentialite">Avis de Confidentialité</a></li>
                        <li><a href="/support">support</a></li>
                    </ul>
                </div>

                {/* Téléchargements */}
                <div className="downloads">
                    <h3>Télécharger</h3>
                    <div className='images'>
                        <div>
                            <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                                <img height={50} src={imagePlayStore} alt="Image à droite" id='rapide' />
                            </a>
                        </div>
                        <div>
                            <a target='_blank' href="https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole">
                                <img height={50} src={imageAppStore} alt="Image à droite" id='rapide' />
                            </a>
                        </div>
                    </div>
                </div>

            </section >

            <section className="section-5 pied-copyright">
                <span className='annee'>2024</span> © by AMOGOTech.
            </section>
            {/* ------------------ PIED DE PAGE ------------------------------ */}


        </div>
    )
}
