import React, { useEffect } from 'react'
import { useState } from 'react';
import qrCode from './../../assets/images/qr-code.png'

export default function AppToStore() {

    const [osys, setOsys] = useState("");

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    }

    useEffect(() => {
        let os = getMobileOperatingSystem();
        setOsys(os);
        if (os == 'iOS') {
            window.location.replace('https://apps.apple.com/app/code-de-la-route-s%C3%A9n%C3%A9gal/id6478462176');
        } else if (os == 'Android' || os == "Windows Phone") {
            window.location.replace('https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole');
        }
    }, [])

    return (
        <div style={{ height: "100vh", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {osys == 'iOS' ? <div>
                <a href='https://apps.apple.com/app/code-de-la-route-s%C3%A9n%C3%A9gal/id6478462176'>Télécharger sur AppStore</a>
            </div>
                : (osys == 'Android' || osys == "Windows Phone") ?
                    <div>
                        <a href='https://play.google.com/store/apps/details?id=com.moussathiam80.autoecole'>Télécharger sur PlayStore</a>
                    </div>
                    :
                    <div style={{ textAlign: 'center' }}>
                        <h4>Scanner se code pour télécharger <br />Code de la route Sénégal</h4>
                        <img width={200} height={200} src={qrCode} alt="ICode QR" /><br /><br />
                        <a href='https://codesenegal.com/'>Visitez notre site</a>
                    </div>}
        </div>
    )
}
