import React from 'react';
import './suppression.css';
import { Link } from 'react-router-dom';
// import logo from '../assets/images/5.jpeg';
export default function SuppressionDeCompte() {
  return (

    <div className='bodySuppression'>

      <div className='texteSuppression'>

        <section className="logoMenu-section">
          {/* <img id='imageConf' src={logo} alt="Logo de l'entreprise" /> */}
          <nav>
            <ul id='menuConf'>
              <li><Link to="/">Accueil</Link></li>
            </ul>
          </nav>
        </section>

        <h1 id='titreSuppression'>Suppression de Compte - CODE DE LA ROUTE SENEGAL</h1>
        <p><h2>Bienvenue sur CODE DE LA ROUTE SENEGAL - votre partenaire pour réussir le code de la route !</h2></p>
        <p>Pour garantir une expérience transparente et respectueuse de la vie privée, nous offrons à nos utilisateurs un moyen simple de supprimer leur compte. Suivez ces étapes pour effectuer la suppression :</p>
        <ol>
          <li><h4>Accédez à l'Option Supprimer Mon Compte :</h4>
            <ul>
              <li>Cliquez sur l'icône des trois barres en haut à gauche pour ouvrir le menu de l'application.</li>
              <li>Faites défiler jusqu'en bas où vous trouverez l'option "Supprimer Mon Compte".</li>
            </ul>
          </li>
          <li><h4>Confirmez Votre Décision :</h4>
            <ul>
              <li>Cliquez sur "Supprimer Mon Compte".</li>
              <li>Une boîte de dialogue apparaîtra avec la question : "Voulez-vous vraiment supprimer votre compte?". Vous aurez le choix entre "Oui" et "Annuler".</li>
              <li>Si vous choisissez "Annuler", votre requête sera annulée. Si vous choisissez "Oui", passez à l'étape suivante.</li>
            </ul>
          </li>
          <li><h4>Confirmation avec Numéro de Téléphone :</h4>
            <ul>
              <li>Une nouvelle boîte de dialogue apparaîtra, vous demandant de confirmer votre demande en entrant votre numéro de téléphone.</li>
              <li>Deux boutons seront présents : "Annuler" (pour annuler l'opération) et "Supprimer" (pour confirmer).</li>
              <li>Si vous choisissez "Annuler", l'opération sera annulée. Si vous choisissez "Supprimer", une dernière boîte de dialogue s'affichera pour vous informer que la suppression sera effective dans une semaine.</li><br></br>
            </ul>
          </li>
        </ol>
        <p><h5 >Nous tenons à souligner que cette démarche est irréversible et entraînera la perte de vos données.<br></br> Nous ne conservons pas vos informations conformément à notre politique de confidentialité.</h5></p><br></br>
        <p>Merci de faire partie de la communauté CODE DE LA ROUTE SENEGAL et n'hésitez pas à nous contacter à [adresse e-mail de contact] pour toute question ou préoccupation.</p>

      </div>
      <section className="bas-de-page">
        <p><strong>2024</strong>  <h8>© by AMOGO Tech .</h8></p>
      </section>

    </div>


  );
}
